import React from "react"
import Img from "gatsby-image/withIEPolyfill"

const style = {
  width: "100%",
  height: "100%",
}

export default ({ coverImage }) => {
  return <Img fluid={coverImage} style={style} alt="Misawaya" />
}
