import React from "react"
import { Helmet } from "react-helmet"
import CoverImage from "../../components/common/CoverImage"
import {
  makeStyles,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"

const useStyles = makeStyles(theme => ({
  branding: {
    height: "50vh", //Fallback
    marginBottom: "30px",
    [theme.breakpoints.up("md")]: {
      height: "100vh", //Fallback
      minHeight: "calc(var(--vh, 1vh) * 100)", //set correct height
      marginBottom: "0",
    },
    display: "grid",
    placeItems: "center",
  },
  form: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}))

export default ({ children, pageSlug, pageTitle, coverImage }) => {
  const classes = useStyles()

  // custom theme
  let customTheme = createMuiTheme({
    palette: {
      primary: {
        main: "#233142",
      },
      secondary: {
        main: "#f95959",
      },
    },
    typography: {
      fontFamily: ["Hammersmith One", "Noto Sans JP"],
    },
  })

  return (
    <>
      <Helmet
        bodyAttributes={{
          class: pageSlug,
        }}
        defaultTitle={pageTitle}
      />
      <ThemeProvider theme={customTheme}>
        <Grid container>
          <Grid item xs={12} md={6} className={classes.branding}>
            <CoverImage coverImage={coverImage} />
          </Grid>
          <Grid item xs={12} md={6} className={classes.form}>
            {children}
          </Grid>
        </Grid>
      </ThemeProvider>
    </>
  )
}
